
import CommoditySearch from '@/core/components/shared/CommoditySearch.vue';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import ReferenceSearch from '@/core/components/shared/ReferenceSearch.vue';
import TerritorySearch from '@/core/components/shared/TerritorySearch.vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { TableOptions } from '@/core/domain/TableOptions';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { CreatePreparation } from '@/freight/application/Preparation/create/CreatePreparation';
import { FindPreparationById } from '@/freight/application/Preparation/search/FindPreparationById';
import { UpdatePreparation } from '@/freight/application/Preparation/update/UpdatePreparation';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Preparation } from '@/freight/domain/Preparation/Preparation';
import { PreparationBulk } from '@/freight/domain/preparationBulk/domain/PreparationBulk';
import { FindAllContainersByStatus } from '@/settings/application/uses_cases/containers/search/FindAllContainersByStatus';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { Containers } from '@/settings/domain/containers/Containers';
import { Weight } from '@/settings/domain/weight/Weight';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { References } from '@/wms/domain/catalogs/references/References';
import { BvModalEvent } from 'bootstrap-vue';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ShippingDetails from '../quotation/form/shippingDetails/ShippingDetails.vue';
import AddPreparationBulk from './components/AddPreparationBulk.vue';
import AddPreparationContainer from './components/AddPreparationContainer.vue';
import AddPreparationPallet from './components/AddPreparationPallet.vue';
import AddPreparationReference from './components/AddPreparationReference.vue';
import { FindAllPackingUnitsByStatus } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitsByStatus';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';

@Component({
  name: 'AddNewPreparationForm',
  components: {
    CustomTableN,
    EntitySearch,
    TerritorySearch,
    ShippingDetails,
    ReferenceSearch,
    AddPreparationContainer,
    AddPreparationPallet,
    AddPreparationBulk,
    AddPreparationReference,
    CommoditySearch
  }
})
export default class AddNewPreparationForm extends Vue {
  @Inject(TYPES.FINDALL_REFERENCE)
  private readonly findAllReference!: ReferenceFindAll;
  @Inject(TYPES.FIND_ALL_CONTAINERS)
  private readonly findAllContainers!: FindAllContainersByStatus;
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  private readonly findActiveCommodity!: FindCommodityTypeByStatus;
  @Inject(TYPES.FINDALL_WEIGHT)
  private readonly findAllWeightUnits!: WeightFindAll;
  @Inject(TYPES.CARGO_DETAILS_PRESET_SAVE)
  private readonly savePreparation!: CreatePreparation;
  @Inject(TYPES.CARGO_DETAILS_PRESET_UPDATE)
  private readonly updateCargoDetailsPreset!: UpdatePreparation;
  @Inject(TYPES.CARGO_DETAILS_PRESET_FIND_BY_ID)
  private readonly findCargoDetailsPresetById!: FindPreparationById;
  @Inject(TYPES.FIND_PACKING_UNITS_BY_STATUS)
  readonly findActivePackingUnits!: FindAllPackingUnitsByStatus;

  @Prop({ default: false }) onClose!: () => Promise<void>;

  //Data
  isLoading = false;
  isEditing = false;
  //Variables
  activeTab = 0;
  timeoutEntity: any;
  searchInterval = 600;
  forView = false;
  form: Preparation = new Preparation();
  lists: {
    containerList: Containers[];
    references: References[];
    weightList: Weight[];
    commodityList?: CommodityType[];
    packagingList?: PackingUnits[];
  } = {
    containerList: [],
    references: [],
    weightList: [],
    commodityList: [],
    packagingList: []
  };

  $refs!: {
    preparationForm: InstanceType<typeof ValidationObserver>;
    formContainer: InstanceType<typeof AddPreparationContainer>;
    formPallet: InstanceType<typeof AddPreparationPallet>;
    formBulk: InstanceType<typeof AddPreparationBulk>;
    formReference: InstanceType<typeof AddPreparationReference>;
  };

  get tabIndex() {
    return this.activeTab;
  }

  set tabIndex(newValue: number) {
    this.$emit('activeTab:update', newValue);
  }

  get disabledInputs() {
    return this.forView || this.isLoading;
  }

  get fields(): TableOptions[] {
    return [
      {
        label: `#`,
        field: 'sequence'
      },
      {
        label: `${this.$t('general.reference')}`,
        field: 'reference',
        formatFn: (item: References) => (item ? `${item.code} - ${item.filterBrand}` : '-')
      },
      {
        label: `${this.$t('general.description')}`,
        field: 'shippingDetails.productName'
      },
      {
        label: `${this.$t('general.dimentions')}`,
        field: (row: PreparationBulk) => `${row.length}x${row.width}x${row.height}`
      },
      {
        label: `${this.$t('general.weight')}`,
        field: 'weight'
      },
      {
        label: `${this.$t('general.weightUnit')}`,
        field: 'weightUnit',
        formatFn: (item: any) => {
          return item ? item.description : `-`;
        }
      },
      {
        label: `${this.$t('general.packaging')}`,
        field: (item: PreparationBulk) => {
          if (item?.containerType) return item.containerType?.description;
          return item.packaging
            ? item.packaging?.unit?.description
            : item.packingUnit
            ? item.packingUnit.description
            : '-';
        }
      },
      {
        label: `${this.$t('general.type')}`,
        field: 'quotationTypeLine'
      },
      {
        label: `${this.$t('general.containerType')}`,
        field: (item: PreparationBulk) => {
          return item.dispatchContainer ? item.dispatchContainer.description : '-';
        }
      },
      {
        label: `${this.$t('general.quantity')}`,
        field: 'quantity',
        hidden: this.forView
      },
      {
        label: `${this.$t('general.actions')}`,
        field: 'actions',
        hidden: this.forView
      }
    ];
  }

  maxSequence() {
    return this.form.bulks.length + 1;
  }

  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.loadtBulkToForm
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.deleteBulk
      }
    ]
  };

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  addToBulks(bulk: PreparationBulk) {
    bulk.sequence = this.maxSequence();
    this.form.bulks.push(bulk);
  }

  async findReferences() {
    try {
      this.isLoading = true;
      const res = await this.findAllReference.internalExecute();
      this.lists.references = res ?? [];
      this.isLoading = false;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findContainers() {
    try {
      this.isLoading = true;
      const res = await this.findAllContainers.internalExecute(true);
      this.lists.containerList = res ?? [];
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findCommodity() {
    try {
      this.isLoading = true;
      const res = await this.findActiveCommodity.internalExecute(true);
      this.lists.commodityList = res ?? [];
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findWeight() {
    try {
      this.isLoading = true;
      const res = await this.findAllWeightUnits.internalExecute();
      this.lists.weightList = res ?? [];
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  getData() {
    this.findReferences();
    this.findContainers();
    this.findCommodity();
    this.findWeight();
    this.getPackingUnits();
  }

  async save() {
    try {
      if (!(await this.$refs.preparationForm.validate())) return;

      this.isLoading = true;
      const payload = Object.assign({}, this.form);
      await this.savePreparation.internalExecute(payload);
      this.$bvModal.hide('add-new-preparation');
      this.reset();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async updatePreset() {
    try {
      this.isLoading = true;
      await this.updateCargoDetailsPreset.internalExecute(this.form);
      this.$bvModal.hide('add-new-preparation');
      this.reset();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findPresetById(id: string) {
    try {
      this.isLoading = true;
      this.isEditing = true;
      return await this.findCargoDetailsPresetById.execute(id);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  factory(e: BvModalEvent) {
    e.preventDefault();
    this.isEditing ? this.updatePreset() : this.save();
  }

  async loadPresetToForm(preset: Preparation) {
    this.isEditing = true;
    this.isLoading = true;
    this.form = Object.assign({}, await this.findPresetById(preset.id));
    this.$bvModal.show('add-new-preparation');
  }

  loadtBulkToForm(bulk: PreparationBulk) {
    try {
      this.setToEspeciphicFormAndTab(bulk);
      this.deleteBulk(bulk);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  deleteBulk(bulk: PreparationBulk) {
    this.form.bulks = this.form.bulks.filter(item => item.sequence !== bulk.sequence);
  }

  setToEspeciphicFormAndTab(item: PreparationBulk) {
    switch (item.cargoType) {
      case 'container':
        this.activeTab = 0;
        this.$refs.formContainer.reset(Object.assign({}, item));
        break;
      case 'pallet':
        this.activeTab = 1;
        break;
      case 'bulk':
        this.activeTab = 2;
        break;
      case 'reference':
        this.activeTab = 3;
        break;
      default:
        break;
    }
  }

  reset() {
    this.form = new Preparation();
  }

  async getPackingUnits() {
    try {
      this.isLoading = true;
      const res = await this.findActivePackingUnits.execute(true);
      res.length > 0 ? (this.lists.packagingList = res) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
